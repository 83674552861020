import React from 'react';
import './AccessPage.css';
import {useNavigate} from 'react-router-dom';


function AccessPage() {
    const navigate = useNavigate()
    const pdfFiles = [
        {
            title: 'PDF 1',
            url: '/access/text',
        },
        {
            title: 'PDF 2',
            url: 'https://example.com/pdf2.pdf',
        },
        {
            title: 'PDF 3',
            url: 'https://example.com/pdf3.pdf',
        },
    ];

    // Function to handle auto-downloading PDF files
    const handleDownload = (url) => {

        const link = document.createElement('a');
        link.href = url;
        link.download = true;
        link.click();
    };

    function logout(){

        fetch("/logout")
            .then(()=> navigate("/"))

            .catch(error => {
                console.error("Failed to log out", error);
                alert("Error logging out. Please try again.");
            })
    }

    return (
        <div>
            <header className="App-header">
                <h1 >Access Page</h1>
                <h2>Download PDF Files:</h2>
                <div className="pdf-links">
                    <ul className="pdf-list">
                        {pdfFiles.map((file, index) => (
                            <li key={index} className="pdf-item">
                                <button
                                    className="pdf-button"
                                    onClick={() => handleDownload(file.url)}
                                >
                                    {file.title}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </header>
            <button
                className="pdf-button"
                onClick={() => logout()}
            > Logout
            </button>
        </div>
    );
}

export default AccessPage;