import React, { useState } from 'react';
import { ethers } from 'ethers';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AccessPage from './AccessPage';
import logoBcrl from './logo-bcrl.svg';
import './App.css';
import './AccessPage.css'
import Main from './Main';

function App() {

    return (

        <div className="App">

            <header className="new-header-class">
                <div className="title-background">
                    <img src="/logo-bcrl.png" alt="Logo" className="logo" />
                    <h1>INSIGHTS HUB</h1>
                    <p>BLOCKCHAIN REALLABOR TOKEN GATED ACCESS</p>
                    <h1>We are giving access to exclusive token-gated content for our NFT holders.</h1>
                </div>
            </header>

            <Routes>
                <Route path="*" element={<Main />} />
                <Route path='/access' element={<AccessPage />} />
            </Routes>


        </div>

    )
}


export default App;